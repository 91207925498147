import ProductItem from "@/components/global/OrderDetail/ProductItem.vue";
import {
  getOrderDetail,
  getBHOOrderDetail,
  getOldO2ODetail
} from './api'
import {
  cancleOrderAll,
  cancleOrderLine
} from '../api'
import Clipboard from "clipboard";
import Process from '../components/Process.vue'
import processRetail from '../components/processRetail'
import bg from '@/assets/purchaseOrder/bg.png'
import moment from "moment";
// import {cancelOrder} from '../components/util'
moment.locale("zh-cn");
import Btn from "@/components/global/Btn/Btn";
import {
  addCart,
  cancleChannelOrder
} from "@/views/order/api";
import { downPoster } from "@/views/monopolyShopActivity/campaignList/api";
import { findUserIdByShopAndPhone, queryUserByPhoneAndShopCode, selectServiceFree } from "@/views/order/details/api";

export default {
  name: "purchaseOrderDetail",
  components: {
    ProductItem,
    Process,
    Btn,
    processRetail
  },
  data() {
    return {
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "",
          name: "",
          title: "采购订单列表"
        },
        {
          path: "/channelOrder/detail",
          name: "detail",
          title: "订单详情"
        }
      ],
      orderDetail: {
        orderHeader: {},
      },
      pageLoadFlag: false,
      bg: bg,
      visible: false,
      fileList:[],
      serviceFeeTotal:0,
      fwfCode:[],
      intendedUserId:'',
      showFeel:false
    };
  },
  computed: {
    totalProd: function () {
      let total = 0
      if (this.orderDetail && Array.isArray(this.orderDetail.salesOrderItem)) {
        this.orderDetail.salesOrderItem.forEach(item => {
          total += item.backnowledgedQty
        })
      }
      return parseInt(total || 0)

    },
    totalPrice: function () {
      let total = 0
      if (this.orderDetail && Array.isArray(this.orderDetail.salesOrderItem)) {
        this.orderDetail.salesOrderItem.forEach(item => {
          total += item.backnowledgedQty * (item.backnowledgedPrice * 100) / 100
        })
      }
      return total
    },
    totalLoadVolume: function () {
      let total = 0
      if (this.orderDetail && Array.isArray(this.orderDetail.salesOrderItem)) {
        this.orderDetail.salesOrderItem.forEach(item => {
          total += item.backnowledgedQty * Math.max(item.volume, 0)
        })
      }
      return total
    }

  },

  mounted() {
    if(this.$route.query.name == '销售订单进度汇总') {
      this.breadcrumbData[1].path = '/channelOrder/salesProgress'
      this.breadcrumbData[1].name = 'salesProgress'
      this.breadcrumbData[1].title = '销售订单进度汇总'
    }
    
    else if(this.$route.query.name == '销售订单列表') {
      this.breadcrumbData[1].path = '/channelOrder/orderList'
      this.breadcrumbData[1].title = '销售订单列表'
    }
    else {
      this.breadcrumbData[1].path = '/channelOrder/orderList'
      this.breadcrumbData[1].title = '采购订单列表'
    }
   
  
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id)
    } else {
      this.$notification.warning({
        message: "订单号错误",
        duration: 2
      });
    }
  },
  
  methods: {
    handleCopyClick(){
      var clipboard = new Clipboard(".copy-button");
      clipboard.on("success", e => {
        this.$message.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        // 不支持复制
        this.$message.warning("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    toProDetail(proCode, orgId) {
      let routeUrl = this.$router.resolve({
        path: '/product/detail',
        query: {
          productCode: proCode,
          orgId: orgId
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    // 退货单号 点击跳转退货单
    gotoDetail(item,type){
      this.$router.push({
        path:type=='zp'?'/salesReturn/genuineReturnDetails':'/hisenseReturn/hisenseReturnDetailNew',
        query: {
          id:item.orderId
        }
      })
    },
    gotoRerurn() {
      let routeUrl = this.$router.resolve({
        path: '/channelReturns/returnEntry',
        query: {
          document: this. orderDetail.documentNum,
          // id: id
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    // 去支付
    handleToPay(item){
      console.log(item);
      if(item.feeList && item.feeList.length > 0) {
        let feeCode = item.feeList[0]
        this.$router.push({
          path: "/serviceFeeModule/payDetail",
          query: {
            id:feeCode,
            batch:0,
            b2bOrderCode: item.documentNum,
            orgName: '订单号',
            channel: 2
          }
        });
      } else {
        this.$message.warning('暂无待支付订单')
      }
    },
    // 下载
    downLoad(file){
      const a = document.createElement('a')
      a.setAttribute('download', file.fileRealName)
      a.setAttribute('target', '_blank')
      a.setAttribute('href', file.fileMapperPath)
      a.click()

    },
    // 下载文件
    handleRemove(file) {

      const a = document.createElement('a')
      a.setAttribute('download', file.fileRealName)
      a.setAttribute('target', '_blank')
      a.setAttribute('href', file.fileMapperPath)
      a.click()
    },
    // 取消订单
    cancleChannelOrderAll() {
      let param = {
        userAccount: this.account,
        purchaseOrderId: this.orderDetail.id
      }
      this.$emit('postSpinTrue', true)
      cancleChannelOrder(param).then(res => {
        this.$emit('postSpinTrue', false)
        let data = JSON.parse(res.data)
        if (data.code == 0) {
          this.$message.success('订单取消成功')
        } else {
          this.$message.success(data.msg)
        }
        this.getChannelList();
      }).catch(err => {
        console.log('err', err);
      })
    },
    goBack() {
      // this.$router.go(-1);
      window.close()
    },
    // 加入购物车
    addCart(proid, orgid) {
      let data = {
        productId: proid,
        orgId: orgid,
        num: 1
      }
      addCart(data).then(res => {
        if (res.data.code == 0) {
          this.$message.success("加入购物车成功");
        } else {
          this.$message.warning(res.data.msg);
        }
      }).catch(error => {
        this.$message.warning('加入购物车失败');
        console.log('加入购物车', error);
      })
    },
    // 查看服务费
    gotoFee(item){
      this.$router.push({
        path: "/serviceFeeModule/serviceDetail",
        query: {
          id:item
        }
      });
    },
    // 查看用户档案
    gotoUser(){
      let data = {
        shopCode: this.orderDetail.storeCode, // 门店id
        phone: this.orderDetail.contactInfo // 手机号
      }
      queryUserByPhoneAndShopCode(data).then(res=>{
        if (res.data.code == 0 ) {
          this.intendedUserId = res.data.data != 0 ? res.data.data : ''
        }
      })
    },
    gotoUserDeatil() {
      this.$router.push({
        path: "/IntentionDetail",
        query: {
          id: this.intendedUserId
        }
      });
    },
    gotoPDetail(code){
      let routeUrl = this.$router.resolve({
        path: "/channelOrder/detail",
        query: {
          id: code
        }
      });
      window.open(routeUrl.href, "_blank");
      // getBHOOrderDetail(data).then(res=>{
      //   if(res.data.orderHeader && res.data.orderHeader.orderId) {
      //     this.$router.push({
      //       path: "/order/detail",
      //       query: { id:  res.data.orderHeader.orderId }
      //     });
      //     return
      //   }
      // })
    },
    gotoBhoDetail(code){
     let data = {
       orderCode:code
     }
      getBHOOrderDetail(data).then(res=>{
        if(res.data.orderHeader && res.data.orderHeader.orderId) {
          this.$router.push({
            path: "/order/detail",
            query: { id:  res.data.orderHeader.orderId }
          });
          return
        }
      })
    },
    
    // 根据旧机单号获取收旧信息
    getOldDetail(id){
      getOldO2ODetail(id).then(res => {
        this.orderDetail.oldMachCategoryName = res.data.data.oldMachCategoryName;
        this.orderDetail.oldMachTreatWayName = res.data.data.oldMachTreatWayName;
      })
    },
   async getDetail(id) {
      this.pageLoadFlag = true
      getOrderDetail(id).then(res => {
        if (typeof res.data == 'string') {
          if(JSON.parse(res.data).code == 0) {
            this.orderDetail = JSON.parse(res.data).data
            if(this.orderDetail.recycleOrderNum && !this.orderDetail.oldMachTreatWayName){
              this.getOldDetail(this.orderDetail.recycleOrderNum);
            }
            this.orderDetail.sfhj = 0
            if(this.orderDetail.salesOrderItem.length>0){
              this.orderDetail.salesOrderItem.forEach(el=>{
                el.sfMoney = 0
                if(el.couponId){
                  el.sfMoney = Number(el.acknowledgedAmount)-(Number(el.couponAmount)*Number(el.backnowledgedQty))
                }else {
                  el.sfMoney = Number(el.acknowledgedAmount)
                }
                this.orderDetail.sfhj+=el.sfMoney
              })
            }
            if( this.orderDetail.salesOrderItem.length>0){
              this.orderDetail.salesOrderItem.forEach(item=>{
               
                if(item.orderItemCouponList&&item.orderItemCouponList.length>0){
                  item.orderItemCouponListBOTTOM = item.orderItemCouponList.filter(it=>it.source!=='海信爱家')
                  item.orderItemCouponListline = item.orderItemCouponList.filter(it=>it.source=='海信爱家')
                
                }
                if(item.couponName){
                  item.orderItemCouponListBOTTOM = item.orderItemCouponListBOTTOM ? item.orderItemCouponListBOTTOM : [];
                  item.orderItemCouponListBOTTOM.push({
                      couponTitle: item.couponAmount,
                      couponCode: item.couponId,
                      sourceName: "以旧换新",
                  });
                }
              })
            }
            this.showFeel = this.orderDetail.feeList && this.orderDetail.feeList.length > 0 ?true :false
            this.gotoUser()
            if(this.orderDetail && this.orderDetail.attachmentList) {
              this.fileList =  this.orderDetail.attachmentList
            }
            if(['04', '08'].includes(this.orderDetail.deliveryMode)) { // 配送方式：直送用户/送装一体
              if(this.orderDetail.dataSource != 'B2B') { // 关联BHO订单不查询服务费明细
                // 订单详情-查询服务费明细
                let orderItemIds = []
                  this.orderDetail.salesOrderItem.map(it=>{
                    if(it.itemId){
                      orderItemIds.push(it.itemId)
                    }
                  })
                let sourceItemIds =  []
                  this.orderDetail.salesOrderItem.forEach(it=>{
                  if(it.sourceItemId){
                    sourceItemIds.push(it.sourceItemId)
                  }
                })
                let data  = {
                  orderType:2,
                  orderItemIds: sourceItemIds && sourceItemIds.length > 0 ? sourceItemIds : orderItemIds
                }
                selectServiceFree(data).then((resA)=>{
                  if(resA.data.code == 0) {
                    this.serviceFeeTotal = resA.data.data.total
                    this.fwfCode = resA.data.data.orderCode
                    resA.data.data.items.forEach(item=>{
                      this.orderDetail.salesOrderItem.forEach(itemTwo=>{
                        // el.itemId == item.orderItemId || el.sourceItemId == item.orderItemId
                        if(item.orderItemId == itemTwo.itemId || item.orderItemId == itemTwo.sourceItemId ) {
                          // itemTwo.serviceFee = item.serviceFee
                          this.$nextTick(()=> {
                            this.$set(itemTwo,'serviceFee',item.serviceFee)
                          })
                        }
                      })
                    })
                  } else {
                    this.serviceFeeTotal = 0
                  }
                })
              }
            }
            this.pageLoadFlag = false
          } else {
            this.$message.warning(JSON.parse(res.data).msg)
            this.pageLoadFlag = false
          }
          this.pageLoadFlag = false
        } else {
          this.$message.warning(res.data.msg)
          this.orderDetail = res.data.data
          this.pageLoadFlag = false
        }
      }).finally(() => {
        this.pageLoadFlag = false
      })
    },
    cancel(type, item) {
      this.ordertypeCancel = type
      this.itemId = item && item.id
      this.cancelOrder(this.orderDetail.orderHeader)
    },
    cancelOrder(item) {
      const orderType = item.orderTypeId;
      const states = item.orderStatusCode;
      if (
        orderType == "8311" &&
        (states == "ALREADYPLANPRODUCT" ||
          states == "ARRANGEDPRODUCT" ||
          states == "UNCHKED" ||
          states == "WAITDELIVER" ||
          states == "PARTCHECKED")
      ) {
        this.$confirm({
          title: '取消提醒',
          content: "取消‘评审通过’,‘已安排生产’,‘待排发货计划’,‘待发货’,‘发货中’状态的常规订单，会判定为商家违约，请确认是否取消？",
          onOk: () => {
            this.visible = true
          },
          onCancel() {

          }
        });
      } else {
        this.visible = true
      }
    },
    getReason(value) {
      this.cancelReason = value
      const id = this.ordertypeCancel == 1 ? this.$route.query.id : this.itemId
      this.cancleSubmit(id, this.ordertypeCancel)
    },
    cancleSubmit(id, type) {
      this.pageLoadFlag = true
      let obj = {
        id: id,
        cancelReason: this.cancelReason
      }
      if (type == 2) {
        cancleOrderLine(obj).then(res => {
          if (res.data.type == 'Y') {
            this.$message.success('订单取消成功')
            this.getDetail(this.$route.query.id)
          }
        }).catch(error => {
          console.log(error);
          this.$message.warning('订单取消失败')
        })
      } else {
        cancleOrderAll(obj).then(res => {
          if (res.data.type == 'Y') {
            this.$message.success('订单取消成功')
            this.getDetail(this.$route.query.id)
          }
        }).catch(error => {
          console.log(error);
          this.$message.warning('订单取消失败')
        })
      }
    },
    handleOk(e) {
      this.visible = false;
    },
    getPrice(item) {
      return ((item.backnowledgedPrice || 0) * 100) * (item.backnowledgedQty || 0) / 100
    },
    gettotal(item) {
      return ((item.backnowledgedPrice || 0) * 100) * (item.backnowledgedQty || 0) / 100
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    gotoProcess() {
      this.$router.push({
        path: '/retailOrder/progress',
        query: {
          id: this.$route.query.id
        }
      })
    }
  }
};