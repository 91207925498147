import service from "@/utils/dmsrequest";
import dmsService from "@/utils/dmsrequest";
import http from "@/utils/request";
//获取渠道订单详情
// export function getOrderDetail(id) {
//   return service({
//     url: "findPurchaseOrderetail",
//     data:{
//       purchaseOrderId:id
//     }
//   });
// }
export function getBHOOrderDetail(params) {
  return http({
    method: "get",
    url: "/order/orderLine.htm",
    params
  });
}
// 获取零售订单详情
export function getOrderDetail(id) {
  return service({
    url: "getSalesOrderDetail",
    data: {
      salesOrderId: id,
      loginType: "CS"
    }
  });
}
// 取消物流
export function cancleLog(data) {
  return dmsService({
    url: 'omsOrderCancle',
    data
  })
}
export function getImg(data) {
  return dmsService({
    url: 'toOmsView',
    data
  })
}
// 查询O2O旧机订单明细
export function getOldO2ODetail(id) {
  return http({
      url: `/aijiaCoupon/queryReatWayAndCategory.nd?recycleOrder=${id}`,
      method: 'get',
      params: {}
  })
}